function Simple() {
    return (
        <main class="">
            <div className="title">
                <h1>Ashish Selvaraj</h1>
            </div>
            <div className="main-text">
                <p>I enjoy building software. Built my first website back in 2014 after discovering HTML and have been hooked ever since.</p>
                <p>Currently a fourth year Computer Science and Business double degree student at the University of Waterloo.</p>
                <p>I’ve previously worked in backend software engineering roles at Microsoft, PENN Entertainment, Loblaw’s, and Thomson Reuters.</p>
                <p>Large amounts of my time are spent writing code, designing, responding to emails and reviews as the proprietor of a mildly popular sudoku app for iOS - Not Evil Sudoku. An app that has somehow crossed 127,000 installs since I first released it in April 2022.</p>
                <p></p>
                <p>You can find my resume <a href="/resume.pdf">here</a>.</p>
                <p>I spend too much time on <a href="https://twitter.com/selvarajashish">x (twitter)</a>.</p>
                <p>Here's a promo video I made for Not Evil Sudoku that I'm vaguely proud of:</p>
            </div>
            <video controls className="video-player">
                <source src="NotEvilSudoku.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </main>
    );
}

export default Simple;
